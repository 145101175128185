import { FunctionComponent } from 'react'
import styled from 'styled-components'

const Main = styled.main`
  display: flex;
  flex-direction: column;
  flex-grow: 1;
  width: 70%;
  margin: 0 auto;
  @media only screen and (max-width: 1200px) {
    width: 80%;
  }
  @media only screen and (max-width: 1200px) {
    width: 95%;
  }
`

export const MainSection: FunctionComponent = props => {
  const { children } = props
  return <Main>{children}</Main>
}
