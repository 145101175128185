import { FunctionComponent } from 'react'
import Link from 'next/link'
import styled from 'styled-components'
import { Button } from '../Button'
import { HtmlProps } from 'next/dist/shared/lib/utils'

const Section = styled.section`
  max-width: 1200px;
  margin-top: 40px;
`
const Title = styled.h3``
const Text = styled.p``

export const WarrantyCta: FunctionComponent<{
  title: string
  text: string
  ctaLabel: string
  ctaHref: string
}> = props => {
  const { title, text, ctaLabel, ctaHref } = props
  return (
    <Section>
      <Title>{title}</Title>
      <Text>
        <div dangerouslySetInnerHTML={{ __html: text }}></div>
      </Text>
      <Button label={ctaLabel} ctaHref={ctaHref} />
    </Section>
  )
}
