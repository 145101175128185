import { FunctionComponent } from 'react'
import Image from 'next/image'
import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`
const TopRow = styled.div``
const BottomRow = styled.div`
  display: flex;
  justify-content: space-between;
  width: 400px;
`

export const LogoSection: FunctionComponent = () => {
  return (
    <Container>
      <TopRow>
        <Image src='/images/PD_Logo.jpeg' alt='Pipedream Products Logo' width={220} height={68} />
      </TopRow>
    </Container>
  )
}
