import {FunctionComponent} from "react";
import Head from "next/head";

export const HeadSection: FunctionComponent<{
    title: string
    metaDescription: string
}> = (props) => {
    const {title, metaDescription} = props
    return <Head>
        <title>{title}</title>
        <meta name="description" content={metaDescription} />
        <link rel="icon" href="/favicon.ico" />
    </Head>
}