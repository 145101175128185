import { HeadSection } from '../components/HeadSection'
import { LogoSection } from '../components/LogoSection'
import { MainSection } from '../components/MainSection'
import { WarrantyCta } from '../components/WarrantyCta'

export default function Home() {
  return (
    <div>
      <HeadSection
        title='Pipedream Products Product Registration and Claim Center'
        metaDescription='Pipedream Products Product Registration and Claim Center'
      />

      <MainSection>
        <LogoSection />
        <WarrantyCta
          title='Register your Product'
          ctaLabel='Register'
          ctaHref='/registration'
          text='• Confirmation of Ownership. <br><br>
          • Record of Proof of Purchase in case you lose your original receipt. <br><br>
          • Be the first to know about new products. <br><br>
• Required to file a Warranty Claim.'
        />
        <WarrantyCta
          title='Warranty Policies'
          ctaLabel='File a Claim'
          ctaHref='/claim'
          text='• Warranty is valid from the date of purchase against mechanical failure or manufacturer defect.<br><br>• Claiming your Warranty requires proof of purchase from a Pipedream Products Authorized Retailer within the warranty period.<br><br>• All Pipedream non-rechargeable (battery-operated) or non-vibrating products qualify for a 90-Day Warranty.<br><br>• All Pipedream rechargeable (plugin) products qualify for a 1-Year Warranty.<br><br>• Edible products are non-refundable. <br><br>• We do not provide or sell replacement chargers at this time.'
        />
      </MainSection>
    </div>
  )
}
